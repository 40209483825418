@import './Utils.scss';

.Navbar {
    background: $grey100;
    color: $text;
    height: 3rem;
    box-sizing: border-box;
    padding: 0 12px;
    z-index: 1000;
    position: fixed;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $grey300;
}

.nav-left-side {
    display: flex;
}

.nav-right-side {
    display: flex;
    align-items: center;
}

.ProjectName {
    margin-right: 1rem;
    position: relative;
    display: flex;
}

.project-name-error {
    position: absolute;
    top: 100%;
    right: 0;
    white-space: nowrap;
    text-align: right;
    background: $grey200;
    padding: 0.5rem;
    border: 1px solid $grey400;
    color: $text;
}

.project-name-display, .project-name-input {
    padding: 0.25rem;
    border: 1px solid $grey400;
    font-style: italic;
    cursor: pointer;
    background: none;
    color: $text;
    box-sizing: border-box;
    font-size: 1rem;
    margin: 0;
}

.project-name-display {
    white-space: nowrap;
}

.NavItem {
    margin-right: 0.25rem;
    color: $text;
}

.nav-item-label {
    cursor: pointer;
    user-select: none;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
}

.nav-item-label:hover {
    background: $grey300;
}

.open-nav-item .nav-item-label {
    background: $grey400;
}

.nav-menu-wrapper {
    position: absolute;
    background: $grey100;
    width: 23rem;
    top: 3rem;
    display: flex;
    flex-direction: column;
}

.nav-menu-wrapper a {
    color: $text;
}

.fonts-list {
    max-height: 20rem;
    overflow-y: scroll;
}

.font-preview {
    margin-right: 0.5rem;
}

.font-preview-list-item {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
    border-bottom: 1px solid $grey300;
}

.font-preview-list-item:last-child {
    border-bottom: none;
}

.import-font-box {
    padding: 0.5rem;
    width: 100%;
    box-sizing: border-box;
}

.directions {
    margin-bottom: 0.5rem;
}

.import-font-input {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 0.5rem;
}

.load-error {
    margin-top: 0.5rem;
}

.ListItemWithSubmenu.import-font .file-list-item {
    border-bottom: none;
}

.Variables-nav-wrapper {
    width: auto;
}

.variables-textarea {
    min-height: 30rem;
    background: $grey100;
    color: $text;
    min-width: 23rem;
    padding: 0.25rem;
    box-sizing: border-box;
    -moz-tab-size : 4;
    -o-tab-size : 4;
    tab-size : 4;
}

.user-info {
    display: flex;
}

.profile-picture {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
}

.Icons {
    flex-basis: 100%;
    overflow: scroll;
    width: 23rem;
}


.search-results {
    overflow: scroll;
    padding-top: 2rem;
}

.search-results .result {
    display: flex;
    padding: 0.5rem;
    border-bottom: 1px solid $grey300;
}

.search-results .result:last-child {
    border-bottom: none;
}

.result-icon {
    margin-right: 0.5rem;
}

.icon-search-box-container {
    display: flex;
}

.search-box-icon {
    position: fixed;
    transform: translate(21.5rem, 0.5rem);
}

.search-box-icon svg {
    height: 1rem;
    width: 1rem;
}

.search-box {
    position: fixed;
    width: 23rem;
    box-sizing: border-box;
    height: 2rem;
    background: $grey450;
    color: $text;
    border: none;
}

// Uploaded images

.Uploads {
    display: flex;
}

.uploaded-image-preview {
    width: 4rem;
    height: 4rem;
    object-fit: contain;
}

.image-and-name {
    display: flex;
}

.image-name {
    font-size: 0.9rem;
    max-width: 15rem;
}

.uploaded-image {
    width: 3rem;
    height: 3rem;
    object-fit: contain;
    background: $grey450;
}

.preview-image {
    width: 3rem;
    height: 3rem;
    object-fit: contain;
    background: $grey450;
}

.directory-item-image {
    width: 3rem;
    height: 3rem;
}

.project-item {
    position: relative;
}

.project-item-info-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
    user-select: none;
    align-items: center;
    border-bottom: 1px solid $grey300;
}

.project-item:last-child .project-item-info-wrapper {
    border-bottom: none;
}

.project-item-right {
    display: flex;
    align-items: center;
}

.project-item-right .button-item {
    margin-right: 0.25rem;
}

.project-item-right .button-item:last-child {
    margin-right: 0;
}

.project-item .Loading {
    height: 1em;
}

.project-item .Loading svg {
    height: 1em;
    width: 1em;
}

.project-open {
    font-size: 0.8rem;
    font-style: italic;
}

.load-more-uploads-wrapper {
    display: flex;
    justify-content: center;
    margin: 0.5rem;
}

.loading-uploads-wrapper {
    margin: 0.5rem;
    height: $loadingHeight;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.directory-item {
    display: flex;
    position: relative;
    border-bottom: 1px solid $grey300;
}

.directory-item-list-content-wrapper {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    width: 100%;
    box-sizing: border-box;
}

.directory-item:last-child {
    border-bottom: none;
}


.directory-item-left-side {
    margin-right: 1rem;
}

.directory-item-actions {
    display: flex;
}

.directory-action {
    margin-right: 0.25rem;
}

.directory-item-name {
    margin-bottom: 0.25rem;
}

.current-directory {
    display: flex;
    align-items: center;
}

$dirWrapperHeight: 2.625rem;

.directory-info-wrapper {
    padding: 0.5rem;
    border-bottom: 1px solid $grey300;
    justify-content: space-between;
    height: $dirWrapperHeight;
    box-sizing: border-box;
}

.list-of-directory-items.overflowing-item {
    max-height: calc(30rem - #{$dirWrapperHeight});
}

.directory-info-right {
    display: flex;
}

.directory-info-right .button-wrapper {
    margin-right: 0.5rem;
}

.directory-info-right .button-wrapper:last-child {
    margin-right: 0;
}

.item-name-left-folder {
    margin-right: 0.5rem;
}

.ImageUpload {
    display: flex;
    flex-direction: column;
    border: 1px solid $grey300;
    box-sizing: border-box;
    background: $grey100;
}

.image-upload-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    cursor: pointer;
    height: 100%;
    background: $grey400;
    padding: 0.5rem;
    margin: 0.5rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
    border: 1px solid $grey450;
}

.image-upload-button:hover {
    background: $grey450;
}

.upload-text {
    margin-right: 0.5rem;
}

.image-upload-controls {
    padding: 0.5rem;
}

.name-preview-line {
    display: flex;
    align-items: center;
    width: 100%;
}

.name-preview-line {
    .name-label {
        margin-right: 0.5rem;
    }
    .uploaded-image-input {
        flex: 1;
        margin-right: 0.5rem;
    }
}

.upload-button-line {
    margin-top: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.image-upload-error {
    margin: 0 0.5rem 0.5rem 0.5rem;
    text-align: center;
    justify-content: center;
}

.num-uploads-left {
    text-align: right;
    color: $grey800;
    font-size: 0.9rem;
}

// Submenu

.file-list-item {
    cursor: pointer;
    user-select: none;
    padding: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $grey300;
}

.file-list-item.right-text-align {
    flex-direction: row-reverse;
}

.file-list-item .Loading {
    height: 1rem;
}

.file-list-item:last-child {
    border-bottom: none;
}

.file-list-item:hover {
    background: $grey200;
}

.editing-list-item {
    background: $grey200;
}

.ListItemWithSubmenu {
    position: relative;
}

.submenu-wrapper {
    right: 0;
    top: 0;
    transform: translate(100%, 0);
    position: absolute;
    background: $grey100;
    display: flex;
    flex-direction: column;
    border: 1px solid $grey300;
}

.submenu-wrapper.left-open-direction {
    right: 100%;
    transform: translate(-1px, 0);
    top: -1px;
}

// Submenu box

.SubmenuBox {
    width: 20rem;
    background: $grey100;
}

// DirectoryContents

.DirectoryContents {
    width: 25rem;
}

.directory-info-wrapper {
    display: flex;
    align-items: center;
}


.project-list-browser-items {
    overflow: scroll;
    max-height: 20rem;
}

.project-list-item {
    padding: 0.5rem;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $grey300;
    cursor: pointer;
    align-items: center;
}

.project-list-item-title {
    margin-right: 0.5rem;
}

.project-list-browser-headline {
    padding: 0.5rem;
    font-weight: bold;
    border-bottom: 1px solid $grey300;
}

.project-list-item:hover {
    background: $grey200;
}

.selected-project-list-item {
    background: $grey200;
}

.project-list-item:last-child {
    border-bottom: none;
}

.hidden-button {
    visibility: hidden;
}

// ActionSubmenu

.ActionSubmenu {
    position: fixed;
    background: $grey100;
    border: 1px solid $grey300;
    box-sizing: border-box;
    visibility: hidden;
}

.confirm-wrapper {
    padding: 0.5rem;
    border: 1px solid $grey300;
}

.are-you-sure-loading {
    margin-top: 0.5rem;
    box-sizing: border-box;
}

.are-you-sure-message {
    margin-bottom: 0.5rem;
    text-align: center;
}

.are-you-sure-buttons {
    display: flex;
    justify-content: center;
}

.are-you-sure-buttons button:first-child {
    margin-right: 0.5rem;
}

.are-you-sure-wrapper {
    padding: 0.5rem;
}

.cancel-button {
    background: none;
}

.overflowing-item {
    max-height: 30rem;
    overflow-y: scroll;
}

.confirm-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

// Completed Message

@keyframes fade-message {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.CompletedMessage {
    animation-name: fade-message;
    animation-duration: 2s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Loading {
    display: flex;
    align-items: center;
    justify-content: center;
}

// Submenu Input

.SubmenuInput {
    padding: 0.5rem;
    box-sizing: border-box;
    width: 20rem;
}

.submenu-input-label {
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.submenu-input-label-right {
    color: $grey800;
    font-size: 0.8rem;
}

.submenu-input-button-input {
    display: flex;
    align-items: center;
}

.submenu-input-area {
    width: 100%;
    box-sizing: border-box;
    margin-right: 0.5rem;
}

.submenu-input-children-wrapper {
    margin-top: 0.5rem;
}

.ErrorMessage {
    text-align: center;
    font-style: italic;
}

// Help

.Help {
    padding: 1rem;
    box-sizing: border-box;
}

.tutorial-label {
    margin: 0 0 0.5rem 0;
}

.explanation {
    margin-bottom: 1rem;
    line-height: 1.4;
}

.explanation pre {
    background: $grey300;
    margin: 0.5rem 0;
    padding: 0.25rem;
    overflow: scroll;
}

.explanation pre:last-child {
    margin-bottom: 0;
}

.explanation pre:first-child {
    margin-bottom: 0.5rem;
}

.explanation:last-child {
    margin-bottom: 0;
}

// Log in area

.LogInArea {
    height: 3rem;
}

.logged-in-container {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.log-in-area-nav-menu {
    position: absolute;
    right: 0;
    top: 100%;
    background: $grey100;
    border: 1px solid $grey300;
    display: flex;
    flex-direction: column;
    min-width: 11rem;
}

.delete-account-nav-item {
    width: 11rem;
}