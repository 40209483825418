@import './Utils.scss';

html,body {
    height: 100%;
    background: $grey900;
    overscroll-behavior: none;
}

.App {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.main-app-area {
    display: flex;
    margin-top: 3rem;
    overflow-y: scroll;
}

button, .button-style {
    background: $grey400;
    color: $text;
    border: 1px solid $grey450;
    border-radius: 0.25rem;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    text-decoration: none;
    cursor: pointer;
    padding: 0.25rem;
    white-space: nowrap;
}

button:hover, .button-style:hover {
    background: $grey450;
}

.button-alt {
    background: $grey600;
    border: 1px solid $grey500;
    color: $inverse-text;
    cursor: pointer;
    border-radius: 0.25rem;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    text-decoration: none;
    padding: 0.25rem;
    white-space: nowrap;
}

.button-alt:hover {
    background: $grey700;
}

.__react_component_tooltip.tooltip {
    padding: 0.5rem;
}

.Loading {
    height: $loadingHeight;
    display: flex;
    align-items: center;
}

.Loading svg {
    height: 1rem;
    fill: $text;
}

input[type="text"] {
    background: $grey400;
    padding: 0.5rem;
    color: $text;
    border: 1px solid $grey300;
}

input[type="text"]::placeholder {
    color: $grey600;
}

.big-loading {
    width: 4rem;
    height: 4rem;
    margin: auto;
}

.big-loading svg {
    width: 4rem;
    height: 4rem;
    fill: $grey100;
}

.HomeNav {
    background: $grey100;
}

.home-nav, .nav-left, .nav-right {
    display: flex;
}

.nav-left, .nav-right {
    align-items: center;
}

.home-nav {
    justify-content: space-between;
    padding: 0.5rem 0;
    height: 4rem;
    align-items: center;
    max-width: 60rem;
    margin: auto;
    box-sizing: border-box;
}

.nav-left {
    align-items: center;
}

.home-nav-item {
    margin-right: 2rem;
}

.home-nav-item:last-child {
    margin-right: 0;
}

.logo-container {
    display: flex;
    align-items: center;
}

.logo-link {
    color: #000;
    text-decoration: none;
    display: flex;
    align-items: center;
}

.logo {
    height: 2rem;
    margin-right: 0.25rem;
}

.logo-text {
    font-weight: bold;
    font-size: 1.4rem;
    color: $text;
}

// Footer


.Footer {
    background: $grey100;
    flex: 1;
    color: $text;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3rem;
}

.Footer a {
    color: $text;
}

.Footer.absolute-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
}

// NotLoggedIn

.not-logged-in-container {
    display: flex;
    flex-direction: column;
    max-width: 60rem;
    margin: 3rem auto 0 auto;
    align-items: center;
}

.not-logged-in-text {
    display: flex;
    font-size: 1.3rem;
    flex-direction: column;
    align-items: center;
}

.not-logged-in-text p {
    margin: 3rem 0 1rem 0;
}

.privacy-not-logged-in {
    margin-top: 1rem;
    font-size: 0.7rem;
}

.logo-spinning-img {
    width: 15rem;
    height: 15rem;
    animation-name: spin;
    animation-duration: 9000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

// Log In Button

.google-icon {
    width: 4rem;
    height: 4rem;
}

.LogInButton {
    display: flex;
    align-items: center;
    background: #1a73e8;
    font-size: 1rem;
    color: #fff;
    font-weight: bold;
    width: 15rem;
    border-radius: 0.2rem;
    box-shadow: 0 2px 10px #ddd;
    cursor: pointer;
}

.LogInButton:hover {
    background: #3081ea;
}

.LogInButton span {
    flex: 1;
    text-align: center;
}

// Privacy Policy

.PrivacyPolicy {
    max-width: 60rem;
    margin: auto;
}

.home-nav-link {
    color: #fff;
    text-decoration: none;
    margin-right: 1rem;
}

.Tooltip {
    position: relative;
    cursor: pointer;
}

.tooltip-message-wrapper {
    position: absolute;
    width: auto;
}

.top-tooltip-wrapper {
    left: 50%;
}

.left-tooltip-wrapper {
    top: 50%;
}

.Tooltip:hover .tooltip-message {
    visibility: visible;
    opacity: 1;
}

.tooltip-children {
    display: flex;
}

.tooltip-action {
    display: flex;
}

.tooltip-message {
    pointer-events: none;
    visibility: hidden;
    background: $grey300;
    color: $text;
    padding: 0.5rem;
    border-radius: 0.5rem;
    position: fixed;
    z-index: 5;
    display: inline-block;
    font-size: 0.8rem;
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0.2s;
    line-height: 1rem;
    box-sizing: border-box;
}

.tooltip-message::after {
    content: " ";
    position: absolute;
    border-width: 0.25rem;
    border-style: solid;
}

.tooltip-message.left-tooltip {
    transform: translate(calc(-100% - 0.5rem), -50%);
}

.tooltip-message.top-tooltip {
    transform: translate(-50%, calc(-100% - 0.5rem));
}

.tooltip-message.bottom-tooltip {
    top: calc(100% + 0.5rem);
    right: 50%;
    transform: translate(50%, 0);
}

.tooltip-message.left-tooltip::after {
    top: 50%;
    left: 100%;
    margin-top: -0.25rem;
    border-color: transparent transparent transparent $grey300;
}

.tooltip-message.top-tooltip::after {
    top: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    border-color: $grey300 transparent transparent transparent;
}

.tooltip-message.bottom-tooltip::after {
    bottom: 100%;
    left: 50%;
    margin-left: -0.25rem;
    border-color: transparent transparent $grey300 transparent;
}

.fixed-tooltip {
    position: fixed;
    display: none;
}

.in-style-list .tooltip-message {
    white-space: pre-line;
}

.in-style-list {
    .tooltip-children, .in-style-list, .tooltip-action {
        width: 100%;
    }
}

@media only screen and (max-width: 768px) {
    .App {
        overscroll-behavior: auto;
        overflow: scroll;
    }
    .main-app-area {
        overflow-x: scroll;
    }
}