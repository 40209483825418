$white: #fefefe;
$black: #090909;

$text: #fff;
$inverse-text: #000;

// $grey100: #f5f6f9;
// $grey200: #e5e8ef;
// $grey300: #d5d9e6;
// $grey400: #c5cbdc;
// $grey450: #aaa;
// $grey500: #666;
// $grey600: #555;
// $grey700: #444;
// $grey800: #333;
// $grey900: #111;

$grey100: #111;
$grey200: #333;
$grey300: #444;
$grey400: #555;
$grey450: #666;
$grey500: #b1b6c6;
$grey600: #c5cbdc;
$grey700: #d5d9e6;
$grey800: #e5e8ef;
$grey900: #f5f6f9;
$lightgreen: #88d8b0;
$lightred:#ff6f69;
$green: #5cb85c;
$red: #d9534f;


$sans: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
sans-serif;

$loadingHeight: 1.625rem;

.light-green {
    background: $lightgreen;
}

.light-red {
    background: $lightred;
}

.grey100 {
    background: $grey100;
}