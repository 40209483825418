@import './Utils.scss';

.ShapeArea {
    margin: 12px;
    width: 100%;
    padding-right: 0;
    box-sizing: border-box;
    display: flex;
    line-height: 1.2;
}

.shape-area-wrapper {
    box-sizing: border-box;
    width: 100%;
}

.active-shape {
    box-shadow: 0 0 0 3px yellow, 0 0 0 6px #000 !important;
}

.ShapeArea .Shape svg {
    display: flex;
}

.background-image-path {
    position: absolute;
    background: $grey400;
    color: $text;
}