@import './Utils.scss';

.home-page-wrapper {
    max-width: 60rem;
    margin: 0 auto;
    background: $text;
    box-sizing: border-box;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.copy-wrapper {
    max-width: 60rem;
    margin: 0 auto;
    padding: 3rem 0;
}

.go-to-app-link {
    color: $inverse-text;
    text-decoration: none;
}

.go-to-app-button {
    background: $grey900;
    padding: 0.5rem;
    border-radius: 0.5rem;
}

.go-to-app-button:hover {
    background: $grey800;
}

.description {
    margin-bottom: 1rem;
    line-height: 1.4;
}

.description:last-child {
    margin-bottom: 0;
}

.cta {
    font-size: 3rem;
    text-align: center;
    margin: 0;
    background: $grey900;
}

.video-block {
    background: $grey200;
}

.video-demo-el {
    border-radius: 0.25rem;
}

.main-text-block {
    background: $white;
}

.main-text-block h2 {
    margin: 0 0 1rem 0;
}

.features-block {
    background: $grey800;
}

// Features

.feature-description {
    margin-bottom: 1.5rem;
    font-weight: bold;
    text-align: center;
}

.feature-example {
    border-radius: 0.5rem;
    display: flex;
}

.feature-example pre {
    font-size: 0.9rem;
}

.feature-example-left, .feature-example-right {
    width: calc(50% - 0.5rem);
    background: $white;
    border-radius: 0.5rem;
}

.feature-example-left {
    margin-right: 0.5rem;
}

.feature-example-right {
    margin-left: 0.5rem;
}

.feature-example-label {
    padding: 1rem;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
}

.feature-example-code {
    padding: 1rem;
    background: $white;
    overflow-x: scroll;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
}

.headline-block {
    background: $grey600;
}

.headline-block h2 {
    margin: 0;
}

.home-feature {
    margin-bottom: 3rem;
}

.home-feature:last-child {
    margin-bottom: 0;
}

// Nav examples

.nav-menu-wrapper.example-nav-menu-wrapper {
    position: unset;
    color: $text;
    width: 100%;
    border-radius: 0.5rem;
}

.nav-menu-wrapper.example-nav-menu-wrapper {
    .DirectoryContents {
        width: 100%;
    }
    .Icons {
        width: 100%;
    }
    .search-box {
        width: 100%;
    }
}

.icon-search-box-container.example-icon-search-box-container {
    position: relative;
}

.search-box.example-search-box {
    position: unset;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
}

.search-box-icon.example-search-box-icon {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(-0.5rem, 0.5rem);
}

.nav-features-container {
    display: flex;
}

.nav-features-container .home-feature {
    flex: 1 1 100%;
}

.nav-features-container .home-feature:first-child {
    margin-right: 0.5rem;
}

.nav-features-container .home-feature:last-child {
    margin-left: 0.5rem;
}

.search-results.example-search-results {
    padding-top: 0;
}

.pricing {
    font-size: 1.2rem;
    text-align: center;
}

.made-by {
    margin-bottom: 0.5rem;
}

@media only screen and (max-width: 768px) {
    .home-nav-link {
        display: none;
    }
    .copy-wrapper {
        padding: 3rem 1rem;
    }
    .nav-left {
        padding-left: 1rem;
    }
    .nav-right {
        padding-right: 1rem;
    }
    .feature-example {
        flex-direction: column;
    }
    .feature-example-left, .feature-example-right {
        width: 100%;
    }
    .feature-example-left {
        margin-right: 0;
        margin-bottom: 1rem;
    }
    .feature-example-right {
        margin-left: 0;
    }
    .nav-features-container {
        flex-direction: column;
        margin-bottom: 0;
    }
    .nav-features-container .home-feature:first-child {
        margin-right: 0;
        margin-bottom: 1.5rem;
    }
    .nav-features-container .home-feature:last-child {
        margin-left: 0;
        margin-bottom: 1.5rem;
    }
    .nav-features-container:last-child .home-feature:last-child {
        margin-bottom: 0;
    }
}