@import './Utils.scss';

.line-item-children {
    padding-left: 1.5rem;
}

.children-list {
    padding-left: 1.5rem;
}

.ComponentEditor {
    height: 100vh;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.active-component-wrapper {
    height: 50%;
    width: 100%;
    border-bottom: 1px solid $grey400;
}

.style-area-wrapper {
    height: 50%;
}

textarea {
    width: 100%;
    line-height: 20px;
    font-size: 0.9rem;
    padding: 0;
    margin: 0;
    border: none;
    max-height: 30rem;
}

pre {
    margin: 0;
    line-height: 20px;
    min-height: 20px;
    -moz-tab-size : 4;
    -o-tab-size : 4;
    tab-size : 4;
}

.CodeEditor {
    position: relative;
    height: 30rem;
    overflow: hidden;
    margin-bottom: 1rem;
    border: 1px solid $grey600;
    height: 50%;
}

.code-editor-textarea {
    position: absolute;
    top: 0;
    height: 100%;
    outline: none;
    background: $grey900;
    color: $inverse-text;
    white-space: nowrap;
    font-family: monospace;
    white-space: pre;
    -moz-tab-size : 4;
    -o-tab-size : 4;
    tab-size : 4;
    resize: none;
    font-size: 0.9rem;
    color: transparent;
    caret-color: #000;
    max-height: 100%;
}

.textarea-overlay {
    position: absolute;
    height: auto;
    top: 0;
    right: 0;
    display: block;
    pointer-events: none;
    overflow: hidden;
    color: $text;
    font-family: monospace;
    -moz-tab-size : 4;
    -o-tab-size : 4;
    tab-size : 4;
    font-size: 0.9rem;
}

.textarea-overlay pre {
    width: 100%;
    user-select: none;
}

.pre-container {
    display: flex;
    width: 100%;
}

.show-hide-buttons {
    position: absolute;
    width: 100%;
    height: auto;
    top: 0;
    display: block;
    overflow: hidden;
    color: $text;
    font-family: monospace;
    width: 20px;
}

.show-hide-line {
    width: 20px;
    height: 20px;
    background: none;
    color: $text;
    border: none;
    cursor: pointer;
    display: block;
    outline: none;
}

.show-hide-line:hover {
    background: $grey400;
}

.selected-line {
    background: rgba(0, 0, 0, 0.08);
    position: relative;
    box-shadow: 0 -1px 0 0 #ccc;
    box-sizing: border-box;
}

.subtle-pre-text {
    color: $grey500;
}

.pre-line-parent {
    position: absolute;
    top: -22px;
    border: 1px solid #ccc;
    border-bottom: 1px solid transparent;
    border-right: none;
    box-sizing: border-box;
    right: 0;
    font-size: 0.7rem;
    text-align: right;
    background: #e4e6e8;
}

.StyleArea textarea {
    background: $grey900;
    color: $inverse-text;
    border: 1px solid $grey600;
}

.style-textarea {
    font-family: monospace;
    -moz-tab-size : 4;
    -o-tab-size : 4;
    tab-size : 4;
    outline: none;
    padding: 0.25rem;
    box-sizing: border-box;
    white-space: nowrap;
    resize: none;
    max-height: 20rem;
}

.style-textarea-container {
    box-sizing: border-box;
    margin-bottom: 1rem;
}

.style-area-shape-buttons {
    display: flex;
    margin-bottom: 1rem;
}

.style-area-shape-buttons button {
    margin-right: 0.5rem;
}

.style-area-shape-buttons button:last-child {
    margin-right: 0;
}

// Info container

.label-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
}

.style-textarea-info-container {
    border: 1px solid $grey400;
    padding: 0.5rem;
    margin: 0.5rem 0;
    font-size: 0.9rem;
    display: flex;
}

.info-container-is-closed, .info-container-is-open {
    display: flex;
}

.style-textarea-right-side {
    display: flex;
    align-items: center;
    position: relative;
}

.style-textarea-action-info {
    cursor: pointer;
    margin-left: 0.5rem;
}

.style-textarea-right-side svg {
    fill: $grey300;
}

.style-textarea-right-side .fill-green svg {
    fill: $green;
}

.style-textarea-right-side .fill-red svg {
    fill: $red;
}

.replace-style-info {
    font-size: 0.9rem;
}

.replace-styles {
    margin-left: 0.5rem;
}

.replace-styles:first-child {
    margin-left: 0;
}

.replace-styles-button {
    display: flex;
}

.Resize {
    width: 30rem;
    position: fixed;
    right: 0;
    top: 3rem;
    bottom: 0;
    background: $grey800;
    display: flex;
}

.resize-handle {
    width: 12px;
    background: $grey800;
    border-left: 1px solid $grey600;
}

.resize-handle:hover {
    background: $grey700;
}

.LayerPanel {
    flex: 1;
    margin: 12px 12px 12px 0;
    color: $inverse-text;
    overflow-y: scroll;
}

.CornerDialog {
    position: fixed;
    right: 0;
    bottom: 0;
    padding: 2rem;
    margin: 1rem;
    background: $grey700;
}

.StylesList {
    z-index: 10;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 1.5rem;
    right: 0;
    border: 1px solid $grey600;
    box-sizing: border-box;
}

.StylesList .style-list {
    overflow-y: scroll;
    max-height: 20rem;
    right: 0;
    background: $grey700;
    min-width: 10rem;
}

.style-list-item {
    padding: 0.25rem;
    height: 1.75rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
}

button.style-list-item-confirm {
    margin-left: 0.5rem;
    background: $grey600;
    color: #000;
    border: 1px solid $grey500;
}

button.style-list-item-confirm:hover {
    background: $grey700;
}

.style-list-item:hover {
    background: $grey600;
}

.selected-style-list-item {
    background: $grey500;
}

.selected-style-list-item:hover {
    background: $grey500;
}